import React, { ReactNode, createContext, useContext } from 'react';

import { PageSearchType } from '@crema/models/ApiData';

export type SearchContextType = {
  searchText: string;
  searchLabel: string;
  results: string[];
  pageResults: PageSearchType;
};

export type SearchActionContextType = {
  setSearchText: (text: string) => void;
  resetSearchText: () => void;
  setSearchLabel: (text: string) => void;
  setPageResults: (items: PageSearchType) => void;
};

const ContextState: SearchContextType = {
  searchText: '',
  searchLabel: '',
  results: [],
  pageResults: [],
};

const SearchContext = createContext<SearchContextType>(ContextState);
const SearchActionsContext = createContext<SearchActionContextType>({
  setSearchText: () => {},
  resetSearchText: () => {},
  setSearchLabel: () => {},
  setPageResults: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

export const useSearchActionsContext = () => useContext(SearchActionsContext);

type Props = {
  children: ReactNode;
};

export const SearchContextProvider = ({ children }: Props) => {
  const [searchText, setSearchText] = React.useState('');
  const [searchLabel, setSearchLabel] = React.useState('');
  const [results, setResults] = React.useState<string[]>([]);
  const [pageResults, setPageResults] = React.useState<PageSearchType>([]);

  const resetSearchText = () => {
    setSearchText('');
  };

  return (
    <SearchContext.Provider
      value={{
        searchText,
        searchLabel,
        results,
        pageResults,
      }}
    >
      <SearchActionsContext.Provider
        value={{
          setSearchText,
          resetSearchText,
          setSearchLabel,
          setPageResults,
        }}
      >
        {children}
      </SearchActionsContext.Provider>
    </SearchContext.Provider>
  );
};
export default SearchContextProvider;
