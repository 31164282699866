import { keys, pick } from 'lodash';

export const parseJson = (jsonString: string | null, defaultReturn = {}) => {
  if (jsonString === null) return defaultReturn;

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultReturn;
  }
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const isSyntheticEvent = (event: any): event is React.SyntheticEvent => {
  return event.nativeEvent instanceof Event;
};

export const duplicateArrayOnlyKeys = <T, X>(
  obj: T[],
  skipKeys: string[]
): X[] => {
  return obj.map((item) => {
    const newItem = pick(item, skipKeys) as X;
    // const newItem = { ...item } as T;

    // // iterate newItem and delete the ones not present in skipKeys
    // Object.keys(newItem).forEach((key) => {
    //   if (!skipKeys.includes(key)) {
    //     delete newItem[key];
    //   }
    // });

    return newItem;
  });
};

export const convertQuestionsListToSimple = (list: any[]): any[] => {
  return list.map((item) => {
    return {
      id: item.id,
      list: duplicateArrayOnlyKeys(item.list, ['id', 'body']),
    };

    // const newItem = pick(item, ['id', 'list']) as X;
    // newItem.list = duplicateArrayOnlyKeys(item.list, ['id', 'body']);
    // const newItem = { ...item } as T;

    // // iterate newItem and delete the ones not present in skipKeys
    // Object.keys(newItem).forEach((key) => {
    //   if (!skipKeys.includes(key)) {
    //     delete newItem[key];
    //   }
    // });

    // return newItem;
  });
};
