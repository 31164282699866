import sanitizeHtml from 'sanitize-html';
import dayjs from 'dayjs';
import { backendUrl } from '@crema/constants/AppConst';
import { stripEndingSlack } from './UrlHelper';
import { backendHost } from '@crema/constants/AppConst';

/**
 * Sanitize Content
 * @param content any
 * @returns content any
 */
export const sanitizeContent = (content: any) => {
  if (typeof content === 'string') {
    return sanitizeHtml(content);
  }

  return content;
};

/**
 * Sanitize a Json Array
 * @param arrayOrObject Array or Object
 * @returns output Array or Object
 */
const sanitizeArrayObject = (arrayOrObject: any) => {
  const output = Array.isArray(arrayOrObject) ? [] : {};

  // loop for an array
  for (const key in arrayOrObject) {
    const item = arrayOrObject[key];
    if (typeof item === 'object' && item instanceof FormData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[key] = item;
    } else if (
      typeof item === 'object' &&
      item !== null &&
      dayjs.isDayjs(item)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[key] = item;
    } else if (
      typeof item === 'object' &&
      item !== null &&
      typeof item.getMonth === 'function'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[key] = item;
    } else if (
      Array.isArray(item) ||
      (typeof item === 'object' && item !== null)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[key] = sanitizeArrayObject(item);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[key] = sanitizeContent(item);
    }
  }

  return output;
};

export const sanitizeData = (inputVal: any) => {
  try {
    if (typeof inputVal === 'object' && inputVal instanceof FormData) {
      return inputVal;
    }

    if (
      Array.isArray(inputVal) ||
      (typeof inputVal === 'object' && inputVal !== null)
    ) {
      return sanitizeArrayObject(inputVal);
    }

    return sanitizeContent(inputVal);
  } catch (e) {
    console.log('parse error', e);
  }
};

export const isRequestSuccessful = (code: number) => {
  return code >= 200 && code <= 204;
};

export const isEmptyObject = (obj = {}) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj?.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const SWRConfigNoRefresh = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateIfStale: false,
  revalidateOnMount: true,
  refreshInterval: 600 * 1000,
};

export const backendFetcher = async (url: string | [string, any]) => {
  let fetchUrl = '';
  if (typeof url === 'string') {
    fetchUrl = url;
  } else {
    const [bUrl, bArguments] = url;
    fetchUrl = bUrl + '?' + new URLSearchParams(bArguments);
  }

  const token =
    localStorage.getItem('token') || sessionStorage.getItem('token');

  const fullUrl = stripEndingSlack(backendUrl) + fetchUrl;
  const response = await fetch(fullUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('An error occurred while fetching the data.');
  }
  return response.json();
};

export const useBackendFetcher = () => {
  const controller = new AbortController();
  const signal = controller.signal;

  const fetcher = (url: string | [string, any]) => {
    let fetchUrl = '';
    if (typeof url === 'string') {
      fetchUrl = url;
    } else {
      const [bUrl, bArguments] = url;
      fetchUrl = bUrl + '?' + new URLSearchParams(bArguments);
    }

    const token =
      localStorage.getItem('token') || sessionStorage.getItem('token');

    //console.debug('useBackendFetcher fetch use token', fetchUrl, token);

    const fullUrl = backendHost + fetchUrl;

    const response = fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      signal,
    })
      .then((res) => {
        //console.log('then response', res);

        if (!res.ok) {
          if (res.status === 401) {
            console.error('token removed');
            localStorage.removeItem('token');

            // since we can't use router redirect, do a normal one
            window.location.href = '/signin';
          }

          throw new Error('An error occurred while fetching the data.');
        }

        return res.json();
      })
      .catch((e) => {
        console.log('useBackendFetcher catch error', e);
      });

    return response;
  };

  return {
    fetcher: fetcher,
    cancel: () => {
      console.log('useBackendFetcher cancel fetcher');
      controller.abort('cancel.abort. new search started');
    },
  };
};
